<template>
    <v-expansion-panel>
        <v-expansion-panel-header hide-actions class="text-subtitle-1 primary--text pb-6">
            <div>
                <v-icon color="primary" size="18" class="mr-2">call_split</v-icon
                >{{ $t('vvtTom.integrityMeasures.headline') }}
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-col cols="12">
                <v-card v-if="!preview || dataIntegrityGuaranteeModel.length" class="default mb-3" outlined height="100%">
                    <v-card-text short flat class="tertiary" v-t="'vvtTom.integrityMeasures.dataIntegrityGuarantee.description'" />
                    <v-card-text>
                        <v-combobox
                            v-if="!preview"
                            :items="dataIntegrityGuaranteeItems"
                            v-model="dataIntegrityGuaranteeModel"
                            v-clear-input-on-change
                            v-clear-input-on-blur
                            :label="$t('vvtTom.integrityMeasures.dataIntegrityGuarantee.label')"
                            :hint="!disableFreetext ? $t('vvtTom.integrityMeasures.dataIntegrityGuarantee.hint') : null"
                            :error-messages="errors.collect(dataIntegrityGuaranteeName)"
                            :data-vv-name="dataIntegrityGuaranteeName"
                            item-value="id"
                            item-text="name"
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            persistent-hint
                            filled
                            background-color="transparent"
                            spellcheck="true"
                            :disabled="disabled"
                            @change="$emit('modified', $event)"
                        >
                            <template v-if="!disableFreetext" v-slot:no-data>
                                <v-alert
                                    type="success"
                                    class="mx-0 my-0 text-body-2"
                                    v-text="$t('createItemHintDefault')"
                                />
                            </template>
                        </v-combobox>
                        <div v-else>
                            <v-chip v-for="item in dataIntegrityGuaranteeModel" :key="item.id" v-text="item.name" class="ma-1" />
                        </div>
                    </v-card-text>
                </v-card>
                <v-card v-if="!preview || transmissionControlModel.length" class="default mb-3" outlined height="100%">
                    <v-card-text short flat class="tertiary" v-t="'vvtTom.integrityMeasures.transmissionControl.description'" />
                    <v-card-text>
                        <v-combobox
                            v-if="!preview"
                            :items="transmissionControlItems"
                            v-model="transmissionControlModel"
                            v-clear-input-on-change
                            v-clear-input-on-blur
                            :label="$t('vvtTom.integrityMeasures.transmissionControl.label')"
                            :hint="!disableFreetext ? $t('vvtTom.integrityMeasures.transmissionControl.hint') : null"
                            :error-messages="errors.collect(transmissionControlName)"
                            :data-vv-name="transmissionControlName"
                            item-value="id"
                            item-text="name"
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            persistent-hint
                            filled
                            background-color="transparent"
                            spellcheck="true"
                            :disabled="disabled"
                            @change="$emit('modified', $event)"
                        >
                            <template v-if="!disableFreetext" v-slot:no-data>
                                <v-alert
                                    type="success"
                                    class="mx-0 my-0 text-body-2"
                                    v-text="$t('createItemHintDefault')"
                                />
                            </template>
                        </v-combobox>
                        <div v-else>
                            <v-chip v-for="item in transmissionControlModel" :key="item.id" v-text="item.name" class="ma-1" />
                        </div>
                    </v-card-text>
                </v-card>
                <v-card v-if="!preview || transportControlModel.length" class="default mb-3" outlined height="100%">
                    <v-card-text short flat class="tertiary" v-t="'vvtTom.integrityMeasures.transportControl.description'" />
                    <v-card-text>
                        <v-combobox
                            v-if="!preview"
                            :items="transportControlItems"
                            v-model="transportControlModel"
                            v-clear-input-on-change
                            v-clear-input-on-blur
                            :label="$t('vvtTom.integrityMeasures.transportControl.label')"
                            :hint="!disableFreetext ? $t('vvtTom.integrityMeasures.transportControl.hint') : null"
                            :error-messages="errors.collect(transportControlName)"
                            :data-vv-name="transportControlName"
                            item-value="id"
                            item-text="name"
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            persistent-hint
                            filled
                            background-color="transparent"
                            spellcheck="true"
                            :disabled="disabled"
                            @change="$emit('modified', $event)"
                        >
                            <template v-if="!disableFreetext" v-slot:no-data>
                                <v-alert
                                    type="success"
                                    class="mx-0 my-0 text-body-2"
                                    v-text="$t('createItemHintDefault')"
                                />
                            </template>
                        </v-combobox>
                        <div v-else>
                            <v-chip v-for="item in transportControlModel" :key="item.id" v-text="item.name" class="ma-1" />
                        </div>
                    </v-card-text>
                </v-card>
                <v-card v-if="!preview || inputControlModel.length" class="default" outlined height="100%">
                    <v-card-text short flat class="tertiary" v-t="'vvtTom.integrityMeasures.inputControl.description'" />
                    <v-card-text>
                        <v-combobox
                            v-if="!preview"
                            :items="inputControlItems"
                            v-model="inputControlModel"
                            v-clear-input-on-change
                            v-clear-input-on-blur
                            :label="$t('vvtTom.integrityMeasures.inputControl.label')"
                            :hint="!disableFreetext ? $t('vvtTom.integrityMeasures.inputControl.hint') : null"
                            :error-messages="errors.collect(inputControlName)"
                            :data-vv-name="inputControlName"
                            item-value="id"
                            item-text="name"
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            persistent-hint
                            filled
                            background-color="transparent"
                            spellcheck="true"
                            :disabled="disabled"
                            @change="$emit('modified', $event)"
                        >
                            <template v-if="!disableFreetext" v-slot:no-data>
                                <v-alert
                                    type="success"
                                    class="mx-0 my-0 text-body-2"
                                    v-text="$t('createItemHintDefault')"
                                />
                            </template>
                        </v-combobox>
                        <div v-else>
                            <v-chip v-for="item in inputControlModel" :key="item.id" v-text="item.name" class="ma-1" />
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import ModelMixin from '@/components/vvt/ModelMixin';
export default {
    name: 'VvtTomIntegrityMeasures',
    mixins: [ModelMixin],
    props: {
        value: {
          type: Object,
          default: () => {
              return {
                  dataIntegrityGuarantee: [],
                  transmissionControl: [],
                  transportControl: [],
                  inputControl: [],
              }
          }
        },
        dataIntegrityGuaranteeItems: {
          type: Array,
          default: () => []
        },
        transmissionControlItems: {
          type: Array,
          default: () => []
        },
        transportControlItems: {
          type: Array,
          default: () => []
        },
        inputControlItems: {
          type: Array,
          default: () => []
        },
        itemsDispatcher: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        preview: {
            type: Boolean,
            default: false,
        },
        disableFreetext: {
            type: Boolean,
            default: false,
        },
    },
    inject: {
        $validator: '$validator',
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/Tom/en.json'),
            de: require('@/locales/vvt/Tom/de.json'),
        },
    },
    data() {
        return {
            dataIntegrityGuaranteeName: 'integrity-measures-data-integrity-guarantee',
            transmissionControlName: 'integrity-measures-transmission-control',
            transportControlName: 'integrity-measures-transport-control',
            inputControlName: 'integrity-measures-input-control',
            dataIntegrityGuaranteeObject: null,
            transmissionControlObject: null,
            transportControlObject: null,
            inputControlObject: null,
        };
    },
    computed: {
        dataIntegrityGuaranteeModel: {
            get() {
                return this.getValue(
                    this.dataIntegrityGuaranteeItems,
                    this.value.dataIntegrityGuarantee
                );
            },
            set(val) {
                if(this.disableFreetext) {
                    val = val.filter(x => typeof x !== 'string' && !(x instanceof String));
                }
                this.transmissionControlObject = this.transmissionControlModel;
                this.transportControlObject = this.transportControlModel;
                this.inputControlObject = this.inputControlModel;
                this.dataIntegrityGuaranteeObject = this.setValue(
                    this.dataIntegrityGuaranteeItems,
                    val,
                    'name',
                    null,
                    this.itemsDispatcher,
                    {
                        field: 'integrityMeasures',
                        subField: 'dataIntegrityGuarantee',
                    }
                );
                this.transformAndEmit();
            },
        },
        transmissionControlModel: {
            get() {
                return this.getValue(
                    this.transmissionControlItems,
                    this.value.transmissionControl
                );
            },
            set(val) {
                if(this.disableFreetext) {
                    val = val.filter(x => typeof x !== 'string' && !(x instanceof String));
                }
                this.dataIntegrityGuaranteeObject = this.dataIntegrityGuaranteeModel;
                this.transportControlObject = this.transportControlModel;
                this.inputControlObject = this.inputControlModel;
                this.transmissionControlObject = this.setValue(
                    this.transmissionControlItems,
                    val,
                    'name',
                    null,
                    this.itemsDispatcher,
                    {
                        field: 'integrityMeasures',
                        subField: 'transmissionControl',
                    }
                );
                this.transformAndEmit();
            },
        },
        transportControlModel: {
            get() {
                return this.getValue(
                    this.transportControlItems,
                    this.value.transportControl
                );
            },
            set(val) {
                if(this.disableFreetext) {
                    val = val.filter(x => typeof x !== 'string' && !(x instanceof String));
                }
                this.dataIntegrityGuaranteeObject = this.dataIntegrityGuaranteeModel;
                this.transmissionControlObject = this.transmissionControlModel;
                this.inputControlObject = this.inputControlModel;
                this.transportControlObject = this.setValue(
                    this.transportControlItems,
                    val,
                    'name',
                    null,
                    this.itemsDispatcher,
                    {
                        field: 'integrityMeasures',
                        subField: 'transportControl',
                    }
                );
                this.transformAndEmit();
            },
        },
        inputControlModel: {
            get() {
                return this.getValue(
                    this.inputControlItems,
                    this.value.inputControl
                );
            },
            set(val) {
                if(this.disableFreetext) {
                    val = val.filter(x => typeof x !== 'string' && !(x instanceof String));
                }
                this.dataIntegrityGuaranteeObject = this.dataIntegrityGuaranteeModel;
                this.transmissionControlObject = this.transmissionControlModel;
                this.transportControlObject = this.transportControlModel;
                this.inputControlObject = this.setValue(
                    this.inputControlItems,
                    val,
                    'name',
                    null,
                    this.itemsDispatcher,
                    {
                        field: 'integrityMeasures',
                        subField: 'inputControl',
                    }
                );
                this.transformAndEmit();
            },
        },
    },
    methods: {
        transformAndEmit() {
            this.$emit('input', {
                dataIntegrityGuarantee: this.dataIntegrityGuaranteeObject.map(
                    x => {
                        if (typeof x !== 'object') {
                            return x;
                        }
                        return x.id ? x.id : x.clientId;
                    }
                ),
                transmissionControl: this.transmissionControlObject.map(x => {
                    if (typeof x !== 'object') {
                        return x;
                    }
                    return x.id ? x.id : x.clientId;
                }),
                transportControl: this.transportControlObject.map(x => {
                    if (typeof x !== 'object') {
                        return x;
                    }
                    return x.id ? x.id : x.clientId;
                }),
                inputControl: this.inputControlObject.map(x => {
                    if (typeof x !== 'object') {
                        return x;
                    }
                    return x.id ? x.id : x.clientId;
                }),
            });
        },
    },
};
</script>
