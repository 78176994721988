<template>
    <v-expansion-panel>
        <v-expansion-panel-header hide-actions class="text-subtitle-1 primary--text pb-6">
            <div>
                <v-icon color="primary" size="18" class="mr-2"
                    >enhanced_encryption</v-icon
                >{{ $t('vvtTom.encryptionMeasures.headline') }}
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-col cols="12">
                <v-card class="default" outlined height="100%">
                    <v-card-text short flat class="tertiary" v-t="'vvtTom.encryptionMeasures.description'" />
                    <v-card-text>
                        <v-combobox
                            v-if="!preview"
                            :items="items"
                            v-model="model"
                            v-clear-input-on-change
                            v-clear-input-on-blur
                            :label="$t('vvtTom.encryptionMeasures.label')"
                            :hint="!disableFreetext ? $t('vvtTom.encryptionMeasures.hint') : null"
                            :error-messages="errors.collect(name)"
                            :data-vv-name="name"
                            item-value="id"
                            item-text="name"
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            persistent-hint
                            filled
                            background-color="transparent"
                            spellcheck="true"
                            :disabled="disabled"
                            @change="$emit('modified', $event)"
                        >
                            <template v-if="!disableFreetext" v-slot:no-data>
                                <v-alert
                                    type="success"
                                    class="mx-0 my-0 text-body-2"
                                    v-text="$t('createItemHintDefault')"
                                />
                            </template>
                        </v-combobox>
                        <div v-else>
                            <v-chip v-for="item in model" :key="item.id" v-text="item.name" class="ma-1" />
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import ModelMixin from '@/components/vvt/ModelMixin';
export default {
    name: 'VvtTomEncryptionMeasures',
    mixins: [ModelMixin],
    props: {
        value: {
          type: Array,
          default: () => []
        },
        items: {
          type: Array,
          default: () => []
        },
        itemsDispatcher: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        preview: {
            type: Boolean,
            default: false,
        },
        disableFreetext: {
            type: Boolean,
            default: false,
        },
    },
    inject: {
        $validator: '$validator',
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/Tom/en.json'),
            de: require('@/locales/vvt/Tom/de.json'),
        },
    },
    data() {
        return {
            name: 'encryption-measures',
        };
    },
    computed: {
        model: {
            get() {
                return this.getValue(this.items, this.value);
            },
            set(val) {
                if(this.disableFreetext) {
                    val = val.filter(x => typeof x !== 'string' && !(x instanceof String));
                }
                const data = this.setValue(
                    this.items,
                    val,
                    'name',
                    null,
                    this.itemsDispatcher,
                    {
                        field: 'encryptionMeasures',
                    }
                );
                this.$emit('input', data);
            },
        },
    },
};
</script>
