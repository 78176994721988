<template>
    <v-expansion-panel>
        <v-expansion-panel-header hide-actions class="text-subtitle-1 primary--text pb-6">
            <div>       
                <v-icon color="primary" size="18" class="mr-2">assignment</v-icon
                >{{ $t('vvtTom.evaluationOfDataProcessingSecurityMeasures.headline') }}
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-col cols="12">
                <v-card v-if="!preview || reviewProcessModel.length" class="default mb-3" outlined height="100%">
                    <v-card-text short flat class="tertiary" v-t="'vvtTom.evaluationOfDataProcessingSecurityMeasures.reviewProcess.description'" />
                    <v-card-text>
                        <v-combobox
                            v-if="!preview"
                            :items="reviewProcessItems"
                            v-model="reviewProcessModel"
                            v-clear-input-on-change
                            v-clear-input-on-blur
                            :label="$t('vvtTom.evaluationOfDataProcessingSecurityMeasures.reviewProcess.label')"
                            :hint="!disableFreetext ? $t('vvtTom.evaluationOfDataProcessingSecurityMeasures.reviewProcess.hint') : null"
                            :error-messages="errors.collect(reviewProcessName)"
                            :data-vv-name="reviewProcessName"
                            item-value="id"
                            item-text="name"
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            persistent-hint
                            filled
                            background-color="transparent"
                            spellcheck="true"
                            :disabled="disabled"
                            @change="$emit('modified', $event)"
                        >
                            <template v-if="!disableFreetext" v-slot:no-data>
                                <v-alert
                                    type="success"
                                    class="mx-0 my-0 text-body-2"
                                    v-text="$t('createItemHintDefault')"
                                />
                            </template>
                        </v-combobox>
                        <div v-else>
                            <v-chip v-for="item in reviewProcessModel" :key="item.id" v-text="item.name" class="ma-1" />
                        </div>
                    </v-card-text>
                </v-card>
                <v-card v-if="!preview || orderControlModel.length" class="default" outlined height="100%">
                    <v-card-text short flat class="tertiary" v-t="'vvtTom.evaluationOfDataProcessingSecurityMeasures.orderControl.description'" />
                    <v-card-text>
                        <v-combobox
                            v-if="!preview"
                            :items="orderControlItems"
                            v-model="orderControlModel"
                            v-clear-input-on-change
                            v-clear-input-on-blur
                            :label="$t('vvtTom.evaluationOfDataProcessingSecurityMeasures.orderControl.label')"
                            :hint="!disableFreetext ? $t('vvtTom.evaluationOfDataProcessingSecurityMeasures.orderControl.hint') : null"
                            :error-messages="errors.collect(orderControlName)"
                            :data-vv-name="orderControlName"
                            item-value="id"
                            item-text="name"
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            persistent-hint
                            filled
                            background-color="transparent"
                            spellcheck="true"
                            :disabled="disabled"
                            @change="$emit('modified', $event)"
                        >
                            <template v-if="!disableFreetext" v-slot:no-data>
                                <v-alert
                                    type="success"
                                    class="mx-0 my-0 text-body-2"
                                    v-text="$t('createItemHintDefault')"
                                />
                            </template>
                        </v-combobox>
                        <div v-else>
                            <v-chip v-for="item in orderControlModel" :key="item.id" v-text="item.name" class="ma-1" />
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import ModelMixin from '@/components/vvt/ModelMixin';
export default {
    name: 'VvtTomEvaluationOfDataProcessingSecurityMeasures',
    mixins: [ModelMixin],
    props: {
        value: {
          type: Object,
          default: () => {
              return {
                  reviewProcess: [],
                  orderControl: []
              }
          }
        },
        reviewProcessItems: {
          type: Array,
          default: () => []
        },
        orderControlItems: {
          type: Array,
          default: () => []
        },
        itemsDispatcher: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        preview: {
            type: Boolean,
            default: false,
        },
        disableFreetext: {
            type: Boolean,
            default: false,
        },
    },
    inject: {
        $validator: '$validator',
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/Tom/en.json'),
            de: require('@/locales/vvt/Tom/de.json'),
        },
    },
    data() {
        return {
            reviewProcessName:
                'evaluation-of-data-processing-security-measures-review-process',
            orderControlName:
                'evaluation-of-data-processing-security-measures-order-control',
            reviewProcessObject: null,
            orderControlObject: null,
        };
    },
    computed: {
        reviewProcessModel: {
            get() {
                return this.getValue(
                    this.reviewProcessItems,
                    this.value.reviewProcess
                );
            },
            set(val) {
                if(this.disableFreetext) {
                    val = val.filter(x => typeof x !== 'string' && !(x instanceof String));
                }
                this.orderControlObject = this.orderControlModel;
                this.reviewProcessObject = this.setValue(
                    this.reviewProcessItems,
                    val,
                    'name',
                    null,
                    this.itemsDispatcher,
                    {
                        field: 'evaluationOfDataProcessingSecurityMeasures',
                        subField: 'reviewProcess',
                    }
                );
                this.transformAndEmit();
            },
        },
        orderControlModel: {
            get() {
                return this.getValue(
                    this.orderControlItems,
                    this.value.orderControl
                );
            },
            set(val) {
                if(this.disableFreetext) {
                    val = val.filter(x => typeof x !== 'string' && !(x instanceof String));
                }
                this.reviewProcessObject = this.reviewProcessModel;
                this.orderControlObject = this.setValue(
                    this.orderControlItems,
                    val,
                    'name',
                    null,
                    this.itemsDispatcher,
                    {
                        field: 'evaluationOfDataProcessingSecurityMeasures',
                        subField: 'orderControl',
                    }
                );
                this.transformAndEmit();
            },
        },
    },
    methods: {
        transformAndEmit() {
            this.$emit('input', {
                reviewProcess: this.reviewProcessObject.map(x => {
                    if (typeof x !== 'object') {
                        return x;
                    }
                    return x.id ? x.id : x.clientId;
                }),
                orderControl: this.orderControlObject.map(x => {
                    if (typeof x !== 'object') {
                        return x;
                    }
                    return x.id ? x.id : x.clientId;
                }),
            });
        },
    },
};
</script>
