<template>
    <v-container fluid class="ma-0 mb-3 pa-0 pl-2 pt-2">
        <v-row>
            <v-col cols="12">
                <v-expansion-panels
                    :value="(this.$wait.is('saving selected item')) ? false : undefined"
                    :disabled="this.$wait.is('saving selected item')"
                >
                    <Documentation
                        editable
                        v-model="model.documentations"
                        @modified="$emit('modified', $event)"
                        @input="changeProperty('documentations', $event)"
                    />
                    <PseudonymisationMeasures
                        v-model="model.pseudonymisationMeasures"
                        :items="tomItems.pseudonymisationMeasures || []"
                        items-dispatcher="tom/unshiftItems"
                        @modified="$emit('modified', $event)"
                        @input="
                            changeProperty(
                                'pseudonymisationMeasures',
                                $event
                            )
                        "
                    />
                    <EncryptionMeasures
                        v-model="model.encryptionMeasures"
                        :items="tomItems.encryptionMeasures || []"
                        items-dispatcher="tom/unshiftItems"
                        @modified="$emit('modified', $event)"
                        @input="
                            changeProperty('encryptionMeasures', $event)
                        "
                    />
                    <ConfidentialityMeasures
                        v-model="model.confidentialityMeasures"
                        :accessControlItems="
                            tomItems.confidentialityMeasures &&
                            tomItems.confidentialityMeasures
                                .accessControl
                                ? tomItems.confidentialityMeasures
                                        .accessControl
                                : []
                        "
                        :systemControlItems="
                            tomItems.confidentialityMeasures &&
                            tomItems.confidentialityMeasures
                                .systemControl
                                ? tomItems.confidentialityMeasures
                                        .systemControl
                                : []
                        "
                        :volumeControlItems="
                            tomItems.confidentialityMeasures &&
                            tomItems.confidentialityMeasures
                                .volumeControl
                                ? tomItems.confidentialityMeasures
                                        .volumeControl
                                : []
                        "
                        :separationControlItems="
                            tomItems.confidentialityMeasures &&
                            tomItems.confidentialityMeasures
                                .separationControl
                                ? tomItems.confidentialityMeasures
                                        .separationControl
                                : []
                        "
                        items-dispatcher="tom/unshiftItems"
                        @modified="$emit('modified', $event)"
                        @input="
                            changeProperty(
                                'confidentialityMeasures',
                                $event
                            )
                        "
                    />
                    <IntegrityMeasures
                        v-model="model.integrityMeasures"
                        :dataIntegrityGuaranteeItems="
                            tomItems.integrityMeasures &&
                            tomItems.integrityMeasures
                                .dataIntegrityGuarantee
                                ? tomItems.integrityMeasures
                                        .dataIntegrityGuarantee
                                : []
                        "
                        :transmissionControlItems="
                            tomItems.integrityMeasures &&
                            tomItems.integrityMeasures
                                .transmissionControl
                                ? tomItems.integrityMeasures
                                        .transmissionControl
                                : []
                        "
                        :transportControlItems="
                            tomItems.integrityMeasures &&
                            tomItems.integrityMeasures.transportControl
                                ? tomItems.integrityMeasures
                                        .transportControl
                                : []
                        "
                        :inputControlItems="
                            tomItems.integrityMeasures &&
                            tomItems.integrityMeasures.inputControl
                                ? tomItems.integrityMeasures
                                        .inputControl
                                : []
                        "
                        items-dispatcher="tom/unshiftItems"
                        @modified="$emit('modified', $event)"
                        @input="
                            changeProperty('integrityMeasures', $event)
                        "
                    />
                    <AvailabilityAndResilienceMeasures
                        v-model="
                            model.availabilityAndResilienceMeasures
                        "
                        :availabilityControlItems="
                            tomItems.availabilityAndResilienceMeasures &&
                            tomItems.availabilityAndResilienceMeasures
                                .availabilityControl
                                ? tomItems
                                        .availabilityAndResilienceMeasures
                                        .availabilityControl
                                : []
                        "
                        :recoverabilityItems="
                            tomItems.availabilityAndResilienceMeasures &&
                            tomItems.availabilityAndResilienceMeasures
                                .recoverability
                                ? tomItems
                                        .availabilityAndResilienceMeasures
                                        .recoverability
                                : []
                        "
                        :reliabilityItems="
                            tomItems.availabilityAndResilienceMeasures &&
                            tomItems.availabilityAndResilienceMeasures
                                .reliability
                                ? tomItems
                                        .availabilityAndResilienceMeasures
                                        .reliability
                                : []
                        "
                        items-dispatcher="tom/unshiftItems"
                        @modified="$emit('modified', $event)"
                        @input="
                            changeProperty(
                                'availabilityAndResilienceMeasures',
                                $event
                            )
                        "
                    />
                    <EvaluationOfDataProcessingSecurityMeasures
                        v-model="
                            model.evaluationOfDataProcessingSecurityMeasures
                        "
                        :reviewProcessItems="
                            tomItems.evaluationOfDataProcessingSecurityMeasures &&
                            tomItems
                                .evaluationOfDataProcessingSecurityMeasures
                                .reviewProcess
                                ? tomItems
                                        .evaluationOfDataProcessingSecurityMeasures
                                        .reviewProcess
                                : []
                        "
                        :orderControlItems="
                            tomItems.evaluationOfDataProcessingSecurityMeasures &&
                            tomItems
                                .evaluationOfDataProcessingSecurityMeasures
                                .orderControl
                                ? tomItems
                                        .evaluationOfDataProcessingSecurityMeasures
                                        .orderControl
                                : []
                        "
                        items-dispatcher="tom/unshiftItems"
                        @modified="$emit('modified', $event)"
                        @input="
                            changeProperty(
                                'evaluationOfDataProcessingSecurityMeasures',
                                $event
                            )
                        "
                    />
                    <FurtherMeasures
                        v-model="model.furtherMeasures"
                        @modified="$emit('modified', $event)"
                        @input="
                            changeProperty('furtherMeasures', $event)
                        "
                    />
                </v-expansion-panels>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Documentation from '@/components/vvt/Tom/Documentation.vue';
import PseudonymisationMeasures from '@/components/vvt/Tom/PseudonymisationMeasures.vue';
import EncryptionMeasures from '@/components/vvt/Tom/EncryptionMeasures.vue';
import ConfidentialityMeasures from '@/components/vvt/Tom/ConfidentialityMeasures.vue';
import IntegrityMeasures from '@/components/vvt/Tom/IntegrityMeasures.vue';
import AvailabilityAndResilienceMeasures from '@/components/vvt/Tom/AvailabilityAndResilienceMeasures.vue';
import EvaluationOfDataProcessingSecurityMeasures from '@/components/vvt/Tom/EvaluationOfDataProcessingSecurityMeasures.vue';
import FurtherMeasures from '@/components/vvt/Tom/FurtherMeasures.vue';

export default {
    name: 'SettingsCompanyTomEditorMask',
    inject: {
        $validator: '$validator',
    },
    props: {
        countryItems: Array,
        value: Object,
        tomItems: Object,
    },
    i18n: {
        messages: {
            en: require('@/locales/Company/en.json'),
            de: require('@/locales/Company/de.json'),
        },
    },
    components: {
        Documentation,
        PseudonymisationMeasures,
        EncryptionMeasures,
        ConfidentialityMeasures,
        IntegrityMeasures,
        AvailabilityAndResilienceMeasures,
        EvaluationOfDataProcessingSecurityMeasures,
        FurtherMeasures,
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set() {},
        },
    },
    methods: {
        changeProperty(property, value) {
            this.$set(this.model, property, value);
            this.$emit('input', this.model);
        },
    },
};
</script>
