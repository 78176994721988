<template>
    <div class="company-tom">
        <CompanyTom />
    </div>
</template>

<script>
import CompanyTom from '@/components/settings/companyTom/index.vue';

export default {
    name: 'SettingsCompanyTom',
    components: {
        CompanyTom,
    },
};
</script>
