<template>
    <v-expansion-panel>
        <v-expansion-panel-header hide-actions class="text-subtitle-1 primary--text pb-6">
            <div>
                <v-icon color="primary" size="18" class="mr-2"
                    >insert_drive_file</v-icon
                >{{ $t('vvtTom.documentation.headline') }}
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-col cols="12">
                <v-btn
                    v-if="editable"
                    @click="newDocumentationDialog"
                    color="success"
                    dark
                    class="mb-2"
                    >{{
                        $t('vvtTom.documentation.createNewDocumentation')
                    }}</v-btn
                >
                <v-dialog
                    v-model="editorDialog"
                    max-width="500px"
                    v-if="editable"
                >
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{
                                $t(
                                    'vvtTom.documentation.' +
                                        formTitleLangKey
                                )
                            }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="editedItem.name"
                                            :label="
                                                $t(
                                                    'vvtTom.documentation.name.label'
                                                )
                                            "
                                            :hint="
                                                $t(
                                                    'vvtTom.documentation.name.hint'
                                                )
                                            "
                                            persistent-hint
                                            spellcheck="true"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-menu
                                            :close-on-content-click="true"
                                            v-model="dateOfStateMenu"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{ on }"
                                            >
                                                <v-text-field
                                                    v-on="on"
                                                    :value="
                                                        formatedDate(
                                                            editedItem.dateOfState
                                                        )
                                                    "
                                                    :label="
                                                        $t(
                                                            'vvtTom.documentation.dateOfState.label'
                                                        )
                                                    "
                                                    :hint="
                                                        $t(
                                                            'vvtTom.documentation.dateOfState.hint'
                                                        )
                                                    "
                                                    persistent-hint
                                                    readonly
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="
                                                    editedItem.dateOfState
                                                "
                                                no-title
                                                @input="
                                                    dateOfStateMenu = false
                                                "
                                                :locale="$i18n.locale"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12">
                                        <div>
                                            <vue-dropzone
                                                :ref="'fileDropZone'"
                                                :id="'fileDropZone'"
                                                :options="dropzoneOptions"
                                                v-model="editedItem.files"
                                                v-on:vdropzone-sending="
                                                    sendFile
                                                "
                                                v-on:vdropzone-success="
                                                    addUpload
                                                "
                                                v-on:vdropzone-removed-file="
                                                    removeUpload
                                                "
                                            ></vue-dropzone>
                                            <div
                                                class="v-messages v-messages--top-border pl-2"
                                            >
                                                <div
                                                    class="v-messages__wrapper"
                                                >
                                                    <div
                                                        class="v-messages__message"
                                                    >
                                                        {{
                                                            $t(
                                                                'vvtTom.documentation.files.hint'
                                                            )
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="secondary"
                                text
                                @click.native="close"
                                >{{ $t('cancel') }}</v-btn
                            >
                            <v-btn
                                color="success"
                                text
                                @click.native="save"
                                >{{ $t('save') }}</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <div class="text-subtitle-1 mt-3">{{ $t('vvtTom.documentation.description') }}</div>
                <v-data-table
                    :headers="tableHeaders"
                    :items="items"
                    hide-default-footer
                    :expanded.sync="expanded"
                    single-expand
                >
                    <template v-slot:item="props">
                        <tr
                            @click="props.expand"
                            class="cursor-pointer"
                            :style="{ borderBottomWidth: props.isExpanded ? 0 : '1px' }"
                        >
                            <td
                                v-if="props.isExpanded"
                                class="text-body-1 primary--text font-weight-bold"
                            >
                                {{ props.item.name }}
                            </td>
                            <td v-else>{{ props.item.name }}</td>
                            <td class="text-right">
                                {{ formatedDate(props.item.dateOfState) }}
                            </td>
                            <td class="text-right" v-if="editable">
                                <v-icon
                                    small
                                    class="mr-2"
                                    @click.stop="editItem(props.item)"
                                >
                                    edit
                                </v-icon>
                                <v-icon
                                    small
                                    @click.stop="deleteItem(props.item)"
                                >
                                    delete
                                </v-icon>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="px-0 tertiary">
                            <v-card flat color="transparent">
                                <v-toolbar flat color="transparent">
                                    <v-toolbar-title class="text-body-1 font-weight-regular px-6 pl-0">
                                    <template v-if="item.files.length <= 0">{{ $t('vvtTom.documentation.files.noFilesUploaded') }}</template>
                                    <template v-else>{{ $t('vvtTom.documentation.files.uploadedFiles') }}</template>
                                    </v-toolbar-title>
                                </v-toolbar>
                                <v-list v-if="item.files.length" class="mb-3 mx-3">
                                    <v-list-item
                                        v-for="file in item.files"
                                        :key="file.uuid"
                                        @click="$getFile(`/api/tom/downloadDocumentation/${file.uuid}`, file.filename, true)"
                                        :disabled="$wait.is('fetching file')"
                                    >
                                        <v-list-item-action>
                                            <v-icon color="primary"
                                                >cloud_download</v-icon
                                            >
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title
                                                v-text="file.filename"
                                            ></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </td>
                    </template>
                    <template v-slot:no-data>
                        <v-alert prominent type="info" icon="mdi-information-outline" class="my-3 d-flex">
                            <v-row align="center">
                                <v-col class="grow">{{ $t('vvtTom.documentation.noDocuments') }}</v-col>
                                <v-col class="shrink">
                                    <v-btn
                                        color="success"
                                        @click="newDocumentationDialog"
                                        v-if="editable"
                                        >{{
                                            $t(
                                                'vvtTom.documentation.createNewDocumentation'
                                            )
                                        }}</v-btn>
                                </v-col>
                            </v-row>
                        </v-alert>
                    </template>
                </v-data-table>

                <v-dialog v-model="deletionDialog" max-width="500px">
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ $t('delete') }}</span>
                        </v-card-title>

                        <v-card-text>
                            <p>
                                {{ $t('vvtTom.documentation.confirmDeletion') }}
                            </p>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="secondary"
                                text
                                @click.native="cancelDeletion"
                                >{{ $t('cancel') }}</v-btn
                            >
                            <v-btn
                                color="error"
                                text
                                @click.native="confirmDeletion"
                                >{{ $t('delete') }}</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import { v4 as uuid } from 'uuid';
import { getToken } from '@/utils/auth';
import { dateTypes, parseISO, formatDate } from '@/utils/dateFns';

export default {
    name: 'VvtTomDocumentation',
    components: {
        vueDropzone,
    },
    props: {
        value: Array,
        editable: {
            type: Boolean,
            default: false,
        },
        preview: {
            type: Boolean,
            default: false,
        },
    },
    inject: {
        $validator: '$validator',
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/Tom/en.json'),
            de: require('@/locales/vvt/Tom/de.json'),
        },
    },
    data() {
        return {
            expanded: [],
            editorDialog: false,
            deletionDialog: false,
            deletionIndex: null,
            dateOfStateMenu: false,
            items: [],
            documentationClientId: '',
            editedIndex: -1,
            editedItem: {
                name: '',
                dateOfState: new Date().toISOString().substring(0, 10),
                files: [],
            },
            defaultItem: {
                name: '',
                dateOfState: new Date().toISOString().substring(0, 10),
                files: [],
            },
            dropzoneOptions: null,
        };
    },
    created() {
        const uploadHeaders = {
            'Cache-Control': null,
            'X-Requested-With': null,
            Accept: `application/json, application/prs.lea+json;v=${process.env.VUE_APP_VERSION}`
        }

        if (process.env.VUE_APP_SSO !== 'true') {
            uploadHeaders.Authorization = `Bearer ${getToken()}`
        }

        this.items = this.value;
        this.dropzoneOptions = {
            headers: uploadHeaders,
            addRemoveLinks: true,
            dictDefaultMessage: this.$i18n.t(
                'vvtTom.documentation.files.label'
            ),
            url: `${process.env.VUE_APP_BASE_URL}/api/admin/companyTom/uploadDocumentation`,
            thumbnailHeight: 120,
            maxFilesize: 20,
            createImageThumbnails: false,
            acceptedFiles:
                'image/*,application/pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptm,.pptx',
        };
    },
    computed: {
        tableHeaders() {
            const arr = [
                {
                    align: 'left',
                    value: 'name',
                    text: this.$t('vvtTom.documentation.name.label')
                },
                {
                    align: 'right',
                    value: 'dateOfState',
                    text: this.$t('vvtTom.documentation.dateOfState.label')
                },
            ];
            if (this.editable) {
                arr.push({
                    value: 'actions',
                    align: 'right',
                    sortable: false,
                });
            }
            return arr;
        },
        formTitleLangKey() {
            return this.editedIndex === -1
                ? 'createNewDocumentation'
                : 'editDocumentation';
        },
    },
    watch: {
        editorDialog(val) {
            val || this.close();
        }
    },
    methods: {
        formatedDate(date) {
            return formatDate(parseISO(date), dateTypes.fullDate);
        },
        editItem(item) {
            this.editedIndex = this.items.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.editorDialog = true;
        },
        deleteItem(item) {
            this.deletionIndex = this.items.indexOf(item);
            this.deletionDialog = true;
        },
        confirmDeletion() {
            if (this.deletionIndex !== null) {
                this.items.splice(this.deletionIndex, 1);
                this.deletionIndex = null;
                this.deletionDialog = false;
                this.$emit('input', this.items);
            }
        },
        cancelDeletion() {
            this.deletionIndex = null;
            this.deletionDialog = false;
        },
        close() {
            this.editorDialog = false;
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
                this.documentationClientId = '';
                this.$refs.fileDropZone.removeAllFiles();
            }, 300);
        },
        setUploadData(uploadData) {
            this.editedItem.files.push(uploadData);
            this.$set(this.items, this.editedIndex, this.editedItem);
            this.$emit('input', this.items);
        },
        save() {
            if (this.editedIndex > -1) {
                this.$set(this.items, this.editedIndex, this.editedItem);
            } else {
                this.editedItem.id = uuid();
                this.items.unshift(this.editedItem);
            }
            this.close();
            this.$emit('input', this.items);
            this.$emit('modified');
        },
        sendFile(file, xhr, formData) {
            formData.append('clientId', file.upload.uuid);
            formData.append(
                'documentationClientId',
                this.documentationClientId
            );
        },
        addUpload(file) {
            this.setUploadData(file.upload);
            this.$emit('modified');
        },
        removeUpload() {
            this.$emit('modified');
        },
        newDocumentationDialog() {
            this.editorDialog = 1;
            this.documentationClientId = uuid();
        },
    },
};
</script>

<style lang="scss" scoped>
.dropzone {
    min-height: 145px;
}
</style>
