<template>
    <div>
        <GenericCrud
            routeName="SettingsCompanyTom"
            routeAction="list"
            :navbarTitle="$t('settings.companyTom.buttonLabel')"
            :navbarBackRouteWithoutHistory="{ name: 'Settings' }"
            :mainListFilterLabel="$t('settings.companies.filterLabel')"
            mainListScrollToSelected
            :storeData="{
                mainListItems: {
                    action: 'companies/fetchAll',
                    payload: { force: true },
                    options: null,
                    itemText: 'title',
                },
                mainItemById: {
                    action: 'companyTom/fetchById',
                    payload: null,
                    options: null,
                },
                edit: {
                    action: 'companyTom/edit',
                    payload: {
                        pseudonymisationMeasuresItems:
                            (this.tomItems &&
                                this.tomItems.pseudonymisationMeasures.filter(
                                    i => i.clientId && !i.id
                                )) ||
                            [],
                        encryptionMeasuresItems:
                            (this.tomItems &&
                                this.tomItems.encryptionMeasures.filter(
                                    i => i.clientId && !i.id
                                )) ||
                            [],
                        confidentialityMeasuresAccessControlItems:
                            (this.tomItems &&
                                this.tomItems.confidentialityMeasures.accessControl.filter(
                                    i => i.clientId && !i.id
                                )) ||
                            [],
                        confidentialityMeasuresSystemControlItems:
                            (this.tomItems &&
                                this.tomItems.confidentialityMeasures.systemControl.filter(
                                    i => i.clientId && !i.id
                                )) ||
                            [],
                        confidentialityMeasuresVolumeControlItems:
                            (this.tomItems &&
                                this.tomItems.confidentialityMeasures.volumeControl.filter(
                                    i => i.clientId && !i.id
                                )) ||
                            [],
                        confidentialityMeasuresSeparationControlItems:
                            (this.tomItems &&
                                this.tomItems.confidentialityMeasures.separationControl.filter(
                                    i => i.clientId && !i.id
                                )) ||
                            [],
                        integrityMeasuresDataIntegrityGuaranteeItems:
                            (this.tomItems &&
                                this.tomItems.integrityMeasures.dataIntegrityGuarantee.filter(
                                    i => i.clientId && !i.id
                                )) ||
                            [],
                        integrityMeasuresTransmissionControlItems:
                            (this.tomItems &&
                                this.tomItems.integrityMeasures.transmissionControl.filter(
                                    i => i.clientId && !i.id
                                )) ||
                            [],
                        integrityMeasuresTransportControlItems:
                            (this.tomItems &&
                                this.tomItems.integrityMeasures.transportControl.filter(
                                    i => i.clientId && !i.id
                                )) ||
                            [],
                        integrityMeasuresInputControlItems:
                            (this.tomItems &&
                                this.tomItems.integrityMeasures.inputControl.filter(
                                    i => i.clientId && !i.id
                                )) ||
                            [],
                        availabilityAndResilienceMeasuresAvailabilityControlItems:
                            (this.tomItems &&
                                this.tomItems.availabilityAndResilienceMeasures.availabilityControl.filter(
                                    i => i.clientId && !i.id
                                )) ||
                            [],
                        availabilityAndResilienceMeasuresRecoverabilityItems:
                            (this.tomItems &&
                                this.tomItems.availabilityAndResilienceMeasures.recoverability.filter(
                                    i => i.clientId && !i.id
                                )) ||
                            [],
                        availabilityAndResilienceMeasuresReliabilityItems:
                            (this.tomItems &&
                                this.tomItems.availabilityAndResilienceMeasures.reliability.filter(
                                    i => i.clientId && !i.id
                                )) ||
                            [],
                        evaluationOfDataProcessingSecurityMeasuresReviewProcessItems:
                            (this.tomItems &&
                                this.tomItems.evaluationOfDataProcessingSecurityMeasures.reviewProcess.filter(
                                    i => i.clientId && !i.id
                                )) ||
                            [],
                        evaluationOfDataProcessingSecurityMeasuresOrderControlItems:
                            (this.tomItems &&
                                this.tomItems.evaluationOfDataProcessingSecurityMeasures.orderControl.filter(
                                    i => i.clientId && !i.id
                                )) ||
                            [],
                    },
                    options: null,
                },
                clipboardProp: 'tom',
                ignoredClipboardProps: ['companyId', 'documentations'],
            }"
            :newItemModelFields="{}"
            :refetchAfterSaving="'tom/fetchItems'"
            :locale="{
                clipboardCopyButton: {
                    tooltip: $t(
                        'settings.companyTom.clipboardCopyButton.tooltip'
                    ),
                    dialog: {
                        title: $t(
                            'settings.companyTom.clipboardCopyButton.dialog.title'
                        ),
                        text: $t(
                            'settings.companyTom.clipboardCopyButton.dialog.text'
                        ),
                        confirmationText: $t('ok'),
                    },
                },
                clipboardPasteButton: {
                    label: $t('settings.companyTom.clipboardPasteButton.label'),
                    tooltip: $t(
                        'settings.companyTom.clipboardPasteButton.tooltip'
                    ),
                    dialog: {
                        title: $t(
                            'settings.companyTom.clipboardPasteButton.dialog.title'
                        ),
                        text: $t(
                            'settings.companyTom.clipboardPasteButton.dialog.text'
                        ),
                        cancelText: $t(
                            'settings.companyTom.clipboardPasteButton.dialog.cancelText'
                        ),
                        confirmationText: $t(
                            'settings.companyTom.clipboardPasteButton.dialog.confirmationText'
                        ),
                    },
                },
            }"
        >
            <template v-slot:editorMask="x">
                <CompanyTomEditorMask
                    v-model="x.selectedMainItem"
                    :countryItems="countryItems"
                    :tomItems="tomItems"
                    :key="x.id"
                    @modified="x.onSetModified"
                />
            </template>
        </GenericCrud>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import GenericCrud from '@/components/generic/GenericCrud.vue';
import CompanyTomEditorMask from './CompanyTomEditorMask.vue';

export default {
    name: 'SettingsCompanyTomIndex',
    components: {
        GenericCrud,
        CompanyTomEditorMask,
    },
    i18n: {
        messages: {
            en: require('@/locales/Settings/en.json'),
            de: require('@/locales/Settings/de.json'),
        },
    },
    computed: {
        ...mapGetters({
            countryItems: 'countries/getItems',
            tomItems: 'tom/getTomItems',
        }),
    },
    methods: {
        ...mapActions({
            fetchCountries: 'countries/fetch',
            fetchTomItems: 'tom/fetchItems',
        }),
        async fetchData() {
            this.$wait.start('pageLoader');
            await Promise.all([this.fetchCountries(), this.fetchTomItems()]);
            this.$wait.end('pageLoader');
        },
    },
    created() {
        this.fetchData();
    },
};
</script>
