<template>
    <v-expansion-panel>
        <v-expansion-panel-header hide-actions class="text-subtitle-1 primary--text pb-6">
            <div>
                <v-icon color="primary" size="18" class="mr-2">notes</v-icon
                >{{ $t('vvtTom.furtherMeasures.headline') }}
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-col cols="12">
                <v-card class="default" outlined height="100%">
                    <v-card-text short flat class="tertiary" v-t="'vvtTom.furtherMeasures.description'" />
                    <v-card-text>
                        <v-textarea
                            v-if="!preview"
                            v-model="model"
                            :label="$t('vvtTom.furtherMeasures.label')"
                            :hint="$t('vvtTom.furtherMeasures.hint')"
                            persistent-hint
                            :error-messages="errors.collect(name)"
                            :data-vv-name="name"
                            spellcheck="true"
                            :disabled="disabled"
                            @input="$emit('modified', $event)"
                        ></v-textarea>
                        <div v-else v-html="model" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import Debounceable from '@/components/Debounceable';

export default {
    name: 'VvtTomFurtherMeasures',
    mixins: [Debounceable],
    inject: {
        $validator: '$validator',
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        preview: {
            type: Boolean,
            default: false,
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/Tom/en.json'),
            de: require('@/locales/vvt/Tom/de.json'),
        },
    },
    data() {
        return {
            name: 'further-measures',
        };
    },
};
</script>
