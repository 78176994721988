var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 mb-3 pa-0 pl-2 pt-2",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"value":(this.$wait.is('saving selected item')) ? false : undefined,"disabled":this.$wait.is('saving selected item')}},[_c('Documentation',{attrs:{"editable":""},on:{"modified":function($event){return _vm.$emit('modified', $event)},"input":function($event){return _vm.changeProperty('documentations', $event)}},model:{value:(_vm.model.documentations),callback:function ($$v) {_vm.$set(_vm.model, "documentations", $$v)},expression:"model.documentations"}}),_c('PseudonymisationMeasures',{attrs:{"items":_vm.tomItems.pseudonymisationMeasures || [],"items-dispatcher":"tom/unshiftItems"},on:{"modified":function($event){return _vm.$emit('modified', $event)},"input":function($event){return _vm.changeProperty(
                            'pseudonymisationMeasures',
                            $event
                        )}},model:{value:(_vm.model.pseudonymisationMeasures),callback:function ($$v) {_vm.$set(_vm.model, "pseudonymisationMeasures", $$v)},expression:"model.pseudonymisationMeasures"}}),_c('EncryptionMeasures',{attrs:{"items":_vm.tomItems.encryptionMeasures || [],"items-dispatcher":"tom/unshiftItems"},on:{"modified":function($event){return _vm.$emit('modified', $event)},"input":function($event){return _vm.changeProperty('encryptionMeasures', $event)}},model:{value:(_vm.model.encryptionMeasures),callback:function ($$v) {_vm.$set(_vm.model, "encryptionMeasures", $$v)},expression:"model.encryptionMeasures"}}),_c('ConfidentialityMeasures',{attrs:{"accessControlItems":_vm.tomItems.confidentialityMeasures &&
                        _vm.tomItems.confidentialityMeasures
                            .accessControl
                            ? _vm.tomItems.confidentialityMeasures
                                    .accessControl
                            : [],"systemControlItems":_vm.tomItems.confidentialityMeasures &&
                        _vm.tomItems.confidentialityMeasures
                            .systemControl
                            ? _vm.tomItems.confidentialityMeasures
                                    .systemControl
                            : [],"volumeControlItems":_vm.tomItems.confidentialityMeasures &&
                        _vm.tomItems.confidentialityMeasures
                            .volumeControl
                            ? _vm.tomItems.confidentialityMeasures
                                    .volumeControl
                            : [],"separationControlItems":_vm.tomItems.confidentialityMeasures &&
                        _vm.tomItems.confidentialityMeasures
                            .separationControl
                            ? _vm.tomItems.confidentialityMeasures
                                    .separationControl
                            : [],"items-dispatcher":"tom/unshiftItems"},on:{"modified":function($event){return _vm.$emit('modified', $event)},"input":function($event){return _vm.changeProperty(
                            'confidentialityMeasures',
                            $event
                        )}},model:{value:(_vm.model.confidentialityMeasures),callback:function ($$v) {_vm.$set(_vm.model, "confidentialityMeasures", $$v)},expression:"model.confidentialityMeasures"}}),_c('IntegrityMeasures',{attrs:{"dataIntegrityGuaranteeItems":_vm.tomItems.integrityMeasures &&
                        _vm.tomItems.integrityMeasures
                            .dataIntegrityGuarantee
                            ? _vm.tomItems.integrityMeasures
                                    .dataIntegrityGuarantee
                            : [],"transmissionControlItems":_vm.tomItems.integrityMeasures &&
                        _vm.tomItems.integrityMeasures
                            .transmissionControl
                            ? _vm.tomItems.integrityMeasures
                                    .transmissionControl
                            : [],"transportControlItems":_vm.tomItems.integrityMeasures &&
                        _vm.tomItems.integrityMeasures.transportControl
                            ? _vm.tomItems.integrityMeasures
                                    .transportControl
                            : [],"inputControlItems":_vm.tomItems.integrityMeasures &&
                        _vm.tomItems.integrityMeasures.inputControl
                            ? _vm.tomItems.integrityMeasures
                                    .inputControl
                            : [],"items-dispatcher":"tom/unshiftItems"},on:{"modified":function($event){return _vm.$emit('modified', $event)},"input":function($event){return _vm.changeProperty('integrityMeasures', $event)}},model:{value:(_vm.model.integrityMeasures),callback:function ($$v) {_vm.$set(_vm.model, "integrityMeasures", $$v)},expression:"model.integrityMeasures"}}),_c('AvailabilityAndResilienceMeasures',{attrs:{"availabilityControlItems":_vm.tomItems.availabilityAndResilienceMeasures &&
                        _vm.tomItems.availabilityAndResilienceMeasures
                            .availabilityControl
                            ? _vm.tomItems
                                    .availabilityAndResilienceMeasures
                                    .availabilityControl
                            : [],"recoverabilityItems":_vm.tomItems.availabilityAndResilienceMeasures &&
                        _vm.tomItems.availabilityAndResilienceMeasures
                            .recoverability
                            ? _vm.tomItems
                                    .availabilityAndResilienceMeasures
                                    .recoverability
                            : [],"reliabilityItems":_vm.tomItems.availabilityAndResilienceMeasures &&
                        _vm.tomItems.availabilityAndResilienceMeasures
                            .reliability
                            ? _vm.tomItems
                                    .availabilityAndResilienceMeasures
                                    .reliability
                            : [],"items-dispatcher":"tom/unshiftItems"},on:{"modified":function($event){return _vm.$emit('modified', $event)},"input":function($event){return _vm.changeProperty(
                            'availabilityAndResilienceMeasures',
                            $event
                        )}},model:{value:(
                        _vm.model.availabilityAndResilienceMeasures
                    ),callback:function ($$v) {_vm.$set(_vm.model, "availabilityAndResilienceMeasures", $$v)},expression:"\n                        model.availabilityAndResilienceMeasures\n                    "}}),_c('EvaluationOfDataProcessingSecurityMeasures',{attrs:{"reviewProcessItems":_vm.tomItems.evaluationOfDataProcessingSecurityMeasures &&
                        _vm.tomItems
                            .evaluationOfDataProcessingSecurityMeasures
                            .reviewProcess
                            ? _vm.tomItems
                                    .evaluationOfDataProcessingSecurityMeasures
                                    .reviewProcess
                            : [],"orderControlItems":_vm.tomItems.evaluationOfDataProcessingSecurityMeasures &&
                        _vm.tomItems
                            .evaluationOfDataProcessingSecurityMeasures
                            .orderControl
                            ? _vm.tomItems
                                    .evaluationOfDataProcessingSecurityMeasures
                                    .orderControl
                            : [],"items-dispatcher":"tom/unshiftItems"},on:{"modified":function($event){return _vm.$emit('modified', $event)},"input":function($event){return _vm.changeProperty(
                            'evaluationOfDataProcessingSecurityMeasures',
                            $event
                        )}},model:{value:(
                        _vm.model.evaluationOfDataProcessingSecurityMeasures
                    ),callback:function ($$v) {_vm.$set(_vm.model, "evaluationOfDataProcessingSecurityMeasures", $$v)},expression:"\n                        model.evaluationOfDataProcessingSecurityMeasures\n                    "}}),_c('FurtherMeasures',{on:{"modified":function($event){return _vm.$emit('modified', $event)},"input":function($event){return _vm.changeProperty('furtherMeasures', $event)}},model:{value:(_vm.model.furtherMeasures),callback:function ($$v) {_vm.$set(_vm.model, "furtherMeasures", $$v)},expression:"model.furtherMeasures"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }