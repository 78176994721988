<template>
    <v-expansion-panel>
        <v-expansion-panel-header hide-actions class="text-subtitle-1 primary--text pb-6">
            <div>
                <v-icon color="primary" size="18" class="mr-2"
                    >power_settings_new</v-icon
                >{{ $t('vvtTom.availabilityAndResilienceMeasures.headline') }}
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-col cols="12">
                <v-card v-if="!preview || availabilityControlModel.length" class="default mb-3" outlined height="100%">
                    <v-card-text short flat class="tertiary" v-t="'vvtTom.availabilityAndResilienceMeasures.availabilityControl.description'" />
                    <v-card-text>
                        <v-combobox
                            v-if="!preview"
                            :items="availabilityControlItems"
                            v-model="availabilityControlModel"
                            v-clear-input-on-change
                            v-clear-input-on-blur
                            :label="$t('vvtTom.availabilityAndResilienceMeasures.availabilityControl.label')"
                            :hint="!disableFreetext ? $t('vvtTom.availabilityAndResilienceMeasures.availabilityControl.hint') : null"
                            :error-messages="errors.collect(availabilityControlName)"
                            :data-vv-name="availabilityControlName"
                            item-value="id"
                            item-text="name"
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            persistent-hint
                            filled
                            background-color="transparent"
                            spellcheck="true"
                            :disabled="disabled"
                            @change="$emit('modified', $event)"
                        >
                            <template v-if="!disableFreetext" v-slot:no-data>
                                <v-alert
                                    type="success"
                                    class="mx-0 my-0 text-body-2"
                                    v-text="$t('createItemHintDefault')"
                                />
                            </template>
                        </v-combobox>
                        <div v-else>
                            <v-chip v-for="item in availabilityControlModel" :key="item.id" v-text="item.name" class="ma-1" />
                        </div>
                    </v-card-text>
                </v-card>
                <v-card v-if="!preview || recoverabilityModel.length" class="default mb-3" outlined height="100%">
                    <v-card-text short flat class="tertiary" v-t="'vvtTom.availabilityAndResilienceMeasures.recoverability.description'" />
                    <v-card-text>
                        <v-combobox
                            v-if="!preview"
                            :items="recoverabilityItems"
                            v-model="recoverabilityModel"
                            v-clear-input-on-change
                            v-clear-input-on-blur
                            :label="$t('vvtTom.availabilityAndResilienceMeasures.recoverability.label')"
                            :hint="!disableFreetext ? $t('vvtTom.availabilityAndResilienceMeasures.recoverability.hint') : null"
                            :error-messages="errors.collect(recoverabilityName)"
                            :data-vv-name="recoverabilityName"
                            item-value="id"
                            item-text="name"
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            persistent-hint
                            filled
                            background-color="transparent"
                            spellcheck="true"
                            :disabled="disabled"
                            @change="$emit('modified', $event)"
                        >
                            <template v-if="!disableFreetext" v-slot:no-data>
                                <v-alert
                                    type="success"
                                    class="mx-0 my-0 text-body-2"
                                    v-text="$t('createItemHintDefault')"
                                />
                            </template>
                        </v-combobox>
                        <div v-else>
                            <v-chip v-for="item in recoverabilityModel" :key="item.id" v-text="item.name" class="ma-1" />
                        </div>
                    </v-card-text>
                </v-card>
                <v-card v-if="!preview || reliabilityModel.length" class="default" outlined height="100%">
                    <v-card-text short flat class="tertiary" v-t="'vvtTom.availabilityAndResilienceMeasures.reliability.description'" />
                    <v-card-text>
                        <v-combobox
                            v-if="!preview"
                            :items="reliabilityItems"
                            v-model="reliabilityModel"
                            v-clear-input-on-change
                            v-clear-input-on-blur
                            :label="$t('vvtTom.availabilityAndResilienceMeasures.reliability.label')"
                            :hint="!disableFreetext ? $t('vvtTom.availabilityAndResilienceMeasures.reliability.hint') : null"
                            :error-messages="errors.collect(reliabilityName)"
                            :data-vv-name="reliabilityName"
                            item-value="id"
                            item-text="name"
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            persistent-hint
                            filled
                            background-color="transparent"
                            spellcheck="true"
                            :disabled="disabled"
                            @change="$emit('modified', $event)"
                        >
                            <template v-if="!disableFreetext" v-slot:no-data>
                                <v-alert
                                    type="success"
                                    class="mx-0 my-0 text-body-2"
                                    v-text="$t('createItemHintDefault')"
                                />
                            </template>
                        </v-combobox>
                        <div v-else>
                            <v-chip v-for="item in reliabilityModel" :key="item.id" v-text="item.name" class="ma-1" />
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import ModelMixin from '@/components/vvt/ModelMixin';

export default {
    name: 'VvtTomAvailabilityAndResilienceMeasures',
    mixins: [ModelMixin],
    props: {
        value: {
          type: Object,
          default: () => {
              return {
                  availabilityControl: [],
                  recoverability: [],
                  reliability: []
              }
          }
        },
        availabilityControlItems: {
          type: Array,
          default: () => []
        },
        recoverabilityItems: {
          type: Array,
          default: () => []
        },
        reliabilityItems: {
          type: Array,
          default: () => []
        },
        itemsDispatcher: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        preview: {
            type: Boolean,
            default: false,
        },
        disableFreetext: {
            type: Boolean,
            default: false,
        },
    },
    inject: {
        $validator: '$validator',
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/Tom/en.json'),
            de: require('@/locales/vvt/Tom/de.json'),
        },
    },
    data() {
        return {
            availabilityControlName: 'availability-and-resilience-measures-availability-control',
            recoverabilityName: 'availability-and-resilience-measures-recoverability',
            reliabilityName: 'availability-and-resilience-measures-reliability',
            availabilityControlObject: null,
            recoverabilityObject: null,
            reliabilityObject: null,
        };
    },
    computed: {
        availabilityControlModel: {
            get() {
                return this.getValue(
                    this.availabilityControlItems,
                    this.value.availabilityControl
                );
            },
            set(val) {
                if(this.disableFreetext) {
                    val = val.filter(x => typeof x !== 'string' && !(x instanceof String));
                }
                this.recoverabilityObject = this.recoverabilityModel;
                this.reliabilityObject = this.reliabilityModel;
                this.availabilityControlObject = this.setValue(
                    this.availabilityControlItems,
                    val,
                    'name',
                    null,
                    this.itemsDispatcher,
                    {
                        field: 'availabilityAndResilienceMeasures',
                        subField: 'availabilityControl',
                    }
                );

                this.transformAndEmit();
            },
        },
        recoverabilityModel: {
            get() {
                return this.getValue(
                    this.recoverabilityItems,
                    this.value.recoverability
                );
            },
            set(val) {
                if(this.disableFreetext) {
                    val = val.filter(x => typeof x !== 'string' && !(x instanceof String));
                }
                this.availabilityControlObject = this.availabilityControlModel;
                this.reliabilityObject = this.reliabilityModel;
                this.recoverabilityObject = this.setValue(
                    this.recoverabilityItems,
                    val,
                    'name',
                    null,
                    this.itemsDispatcher,
                    {
                        field: 'availabilityAndResilienceMeasures',
                        subField: 'recoverability',
                    }
                );

                this.transformAndEmit();
            },
        },
        reliabilityModel: {
            get() {
                return this.getValue(
                    this.reliabilityItems,
                    this.value.reliability
                );
            },
            set(val) {
                if(this.disableFreetext) {
                    val = val.filter(x => typeof x !== 'string' && !(x instanceof String));
                }
                this.availabilityControlObject = this.availabilityControlModel;
                this.recoverabilityObject = this.recoverabilityModel;
                this.reliabilityObject = this.setValue(
                    this.reliabilityItems,
                    val,
                    'name',
                    null,
                    this.itemsDispatcher,
                    {
                        field: 'availabilityAndResilienceMeasures',
                        subField: 'reliability',
                    }
                );

                this.transformAndEmit();
            },
        },
    },
    methods: {
        transformAndEmit() {
            this.$emit('input', {
                availabilityControl: this.availabilityControlObject.map(x => {
                    if (typeof x !== 'object') {
                        return x;
                    }
                    return x.id ? x.id : x.clientId;
                }),
                recoverability: this.recoverabilityObject.map(x => {
                    if (typeof x !== 'object') {
                        return x;
                    }
                    return x.id ? x.id : x.clientId;
                }),
                reliability: this.reliabilityObject.map(x => {
                    if (typeof x !== 'object') {
                        return x;
                    }
                    return x.id ? x.id : x.clientId;
                }),
            });
        },
    },
};
</script>
