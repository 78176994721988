var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GenericCrud',{attrs:{"routeName":"SettingsCompanyTom","routeAction":"list","navbarTitle":_vm.$t('settings.companyTom.buttonLabel'),"navbarBackRouteWithoutHistory":{ name: 'Settings' },"mainListFilterLabel":_vm.$t('settings.companies.filterLabel'),"mainListScrollToSelected":"","storeData":{
            mainListItems: {
                action: 'companies/fetchAll',
                payload: { force: true },
                options: null,
                itemText: 'title',
            },
            mainItemById: {
                action: 'companyTom/fetchById',
                payload: null,
                options: null,
            },
            edit: {
                action: 'companyTom/edit',
                payload: {
                    pseudonymisationMeasuresItems:
                        (this.tomItems &&
                            this.tomItems.pseudonymisationMeasures.filter(
                                function (i) { return i.clientId && !i.id; }
                            )) ||
                        [],
                    encryptionMeasuresItems:
                        (this.tomItems &&
                            this.tomItems.encryptionMeasures.filter(
                                function (i) { return i.clientId && !i.id; }
                            )) ||
                        [],
                    confidentialityMeasuresAccessControlItems:
                        (this.tomItems &&
                            this.tomItems.confidentialityMeasures.accessControl.filter(
                                function (i) { return i.clientId && !i.id; }
                            )) ||
                        [],
                    confidentialityMeasuresSystemControlItems:
                        (this.tomItems &&
                            this.tomItems.confidentialityMeasures.systemControl.filter(
                                function (i) { return i.clientId && !i.id; }
                            )) ||
                        [],
                    confidentialityMeasuresVolumeControlItems:
                        (this.tomItems &&
                            this.tomItems.confidentialityMeasures.volumeControl.filter(
                                function (i) { return i.clientId && !i.id; }
                            )) ||
                        [],
                    confidentialityMeasuresSeparationControlItems:
                        (this.tomItems &&
                            this.tomItems.confidentialityMeasures.separationControl.filter(
                                function (i) { return i.clientId && !i.id; }
                            )) ||
                        [],
                    integrityMeasuresDataIntegrityGuaranteeItems:
                        (this.tomItems &&
                            this.tomItems.integrityMeasures.dataIntegrityGuarantee.filter(
                                function (i) { return i.clientId && !i.id; }
                            )) ||
                        [],
                    integrityMeasuresTransmissionControlItems:
                        (this.tomItems &&
                            this.tomItems.integrityMeasures.transmissionControl.filter(
                                function (i) { return i.clientId && !i.id; }
                            )) ||
                        [],
                    integrityMeasuresTransportControlItems:
                        (this.tomItems &&
                            this.tomItems.integrityMeasures.transportControl.filter(
                                function (i) { return i.clientId && !i.id; }
                            )) ||
                        [],
                    integrityMeasuresInputControlItems:
                        (this.tomItems &&
                            this.tomItems.integrityMeasures.inputControl.filter(
                                function (i) { return i.clientId && !i.id; }
                            )) ||
                        [],
                    availabilityAndResilienceMeasuresAvailabilityControlItems:
                        (this.tomItems &&
                            this.tomItems.availabilityAndResilienceMeasures.availabilityControl.filter(
                                function (i) { return i.clientId && !i.id; }
                            )) ||
                        [],
                    availabilityAndResilienceMeasuresRecoverabilityItems:
                        (this.tomItems &&
                            this.tomItems.availabilityAndResilienceMeasures.recoverability.filter(
                                function (i) { return i.clientId && !i.id; }
                            )) ||
                        [],
                    availabilityAndResilienceMeasuresReliabilityItems:
                        (this.tomItems &&
                            this.tomItems.availabilityAndResilienceMeasures.reliability.filter(
                                function (i) { return i.clientId && !i.id; }
                            )) ||
                        [],
                    evaluationOfDataProcessingSecurityMeasuresReviewProcessItems:
                        (this.tomItems &&
                            this.tomItems.evaluationOfDataProcessingSecurityMeasures.reviewProcess.filter(
                                function (i) { return i.clientId && !i.id; }
                            )) ||
                        [],
                    evaluationOfDataProcessingSecurityMeasuresOrderControlItems:
                        (this.tomItems &&
                            this.tomItems.evaluationOfDataProcessingSecurityMeasures.orderControl.filter(
                                function (i) { return i.clientId && !i.id; }
                            )) ||
                        [],
                },
                options: null,
            },
            clipboardProp: 'tom',
            ignoredClipboardProps: ['companyId', 'documentations'],
        },"newItemModelFields":{},"refetchAfterSaving":'tom/fetchItems',"locale":{
            clipboardCopyButton: {
                tooltip: _vm.$t(
                    'settings.companyTom.clipboardCopyButton.tooltip'
                ),
                dialog: {
                    title: _vm.$t(
                        'settings.companyTom.clipboardCopyButton.dialog.title'
                    ),
                    text: _vm.$t(
                        'settings.companyTom.clipboardCopyButton.dialog.text'
                    ),
                    confirmationText: _vm.$t('ok'),
                },
            },
            clipboardPasteButton: {
                label: _vm.$t('settings.companyTom.clipboardPasteButton.label'),
                tooltip: _vm.$t(
                    'settings.companyTom.clipboardPasteButton.tooltip'
                ),
                dialog: {
                    title: _vm.$t(
                        'settings.companyTom.clipboardPasteButton.dialog.title'
                    ),
                    text: _vm.$t(
                        'settings.companyTom.clipboardPasteButton.dialog.text'
                    ),
                    cancelText: _vm.$t(
                        'settings.companyTom.clipboardPasteButton.dialog.cancelText'
                    ),
                    confirmationText: _vm.$t(
                        'settings.companyTom.clipboardPasteButton.dialog.confirmationText'
                    ),
                },
            },
        }},scopedSlots:_vm._u([{key:"editorMask",fn:function(x){return [_c('CompanyTomEditorMask',{key:x.id,attrs:{"countryItems":_vm.countryItems,"tomItems":_vm.tomItems},on:{"modified":x.onSetModified},model:{value:(x.selectedMainItem),callback:function ($$v) {_vm.$set(x, "selectedMainItem", $$v)},expression:"x.selectedMainItem"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }